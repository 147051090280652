import * as React from "react";
import { FilmRating, ScreeningsData } from "../../types/types.ts";
import { Box, useTheme } from "@mui/material";
import { FilmTextInfoContainer } from "./FilmTextInfoContainer.tsx";
import { ScreeningsAccordion } from "../Screenings/ScreeningsAccordion.tsx";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const imageBoxSx = {
  minWidth: { xs: "110px", md: "140px" }, // Responsive width
  maxWidth: { xs: "110px", md: "140px" },
  height: { xs: "165px", md: "210px" }, // Responsive height
  boxShadow: {
    xs: "0px 0px 10px 5px rgba(255, 255, 255, 0.1)", // Smaller shadow on mobile
    md: "0px 0px 20px 10px rgba(255, 255, 255, 0.1)", // Larger shadow on desktop
  },
  marginTop: { xs: "3px", md: "7px" },
  marginBottom: { xs: "3px", md: "5px" },
  borderRadius: "2px",
};

export const FilmTile: React.FC<{
  title: string;
  description: string;
  screenings: ScreeningsData[];
  image_link: string | null;
  rating: FilmRating;
  lengthInMinutes: number | null;
  tabDateLabel: string;
  lazy: boolean;
}> = ({
  title,
  description,
  screenings,
  image_link,
  rating,
  lengthInMinutes,
  tabDateLabel,
  lazy,
}) => {
  const theme = useTheme();
  const [isImageError, setIsImageError] = React.useState(false);

  const renderImage = () => {
    if (image_link && !isImageError) {
      return (
        <Box
          component="img"
          src={image_link}
          alt={`Poster for ${title}`}
          loading={lazy ? "lazy" : "eager"}
          sx={imageBoxSx}
          onError={() => setIsImageError(true)}
        />
      );
    } else {
      return (
        <Box
          sx={{
            ...imageBoxSx,
            display: "flex",
            alignItems: "center",
          }}
        >
          <QuestionMarkIcon
            sx={{
              fontSize: { xs: 110, md: 140 },
            }}
          />
        </Box>
      );
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: { xs: 3, md: 5 },
          borderBottom: `2px solid ${theme.palette.divider}`,
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: { xs: "165px", md: "210px" },
          }}
        >
          {lazy ? (
            <LazyLoadComponent
              key={`${title}_${tabDateLabel}_image`}
              threshold={500}
            >
              {renderImage()}
            </LazyLoadComponent>
          ) : (
            renderImage()
          )}

          <FilmTextInfoContainer
            title={title}
            description={description}
            rating={rating}
            lengthInMinutes={lengthInMinutes}
          />
        </Box>
        <Box
          sx={{
            marginLeft: { xs: "0px", md: "155px" }, // Responsive width
            marginTop: 2,
          }}
        >
          <Box>
            <ScreeningsAccordion
              screeningsByDate={screenings}
              filmTitle={title}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};
