import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { functions } from "../config/firebaseConfig.ts";
import { DataFromFetchScreenings, Venue } from "../types/types.ts";
import { mockResponse } from "./mockData.js";

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const fetchScreenings = async (
  venues: string[]
): Promise<DataFromFetchScreenings> => {
  if (process.env.NODE_ENV === "development") {
    return mockResponse as DataFromFetchScreenings;
  }
  const response = httpsCallable(functions, "getScreenings");
  return response({ venues }).then((response) => {
    return response.data as DataFromFetchScreenings;
  });
};

export const fetchVenuesByLocation = async ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): Promise<Venue[]> => {
  const response = httpsCallable(functions, "getVenuesWithinRadius");
  return response({ userLatitude: latitude, userLongitude: longitude }).then(
    (response) => {
      return response.data as Venue[];
    }
  );
};

export const recordExternalScreeningClick = async (
  filmTitle: string,
  venueName: string,
  time: string
) => {
  if (process.env.NODE_ENV !== "development") {
    const response = httpsCallable(functions, "recordExternalScreeningClick");
    return response({
      film_title: filmTitle,
      venue_name: venueName,
      time_of_screening: time,
    });
  }
};
