import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { LoadingElement } from "../components/LoadingElement/LoadingElement.tsx";
import { ScreeningsInDateRange } from "../components/../types/types.ts";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchScreenings } from "../helpers/onCallFunctions.ts";
import { useTheme } from "@mui/material";
import { FilmTab } from "../components/Tabs/FilmTab.tsx";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: { xs: 1.5, md: 3 },
          }}
        >
          <>{children}</>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const FilmListings = () => {
  const [queryStringParameters] = useSearchParams();
  const [value, setValue] = React.useState(0);
  const [filmsByDateRange, setFilmsByDateRange] = React.useState<
    ScreeningsInDateRange[]
  >([]);

  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  React.useEffect(() => {
    const fetchScreeningsData = async () => {
      try {
        const venues = queryStringParameters.getAll("cinemas");
        const { screenings_by_date, all_screenings_on_all_dates } =
          await fetchScreenings(venues);
        setFilmsByDateRange([
          all_screenings_on_all_dates,
          ...screenings_by_date,
        ]);
      } catch (error) {
        console.error("Error fetching screenings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchScreeningsData();
  }, [queryStringParameters]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <LoadingElement></LoadingElement>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          md: "65%",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0px auto",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile={true}
        aria-label="scrollable force tabs example"
        sx={{ width: "100%" }}
        scrollButtons="auto"
        TabScrollButtonProps={{
          sx: {
            color: theme.palette.primary.main,
            visibility: "visible",
            "&.Mui-disabled": {
              opacity: 0.2,
            },
          },
        }}
      >
        {filmsByDateRange.map(({ tab_date_label, film_data }, index) =>
          film_data.length > 0 ? (
            <Tab
              label={tab_date_label}
              {...a11yProps(index)}
              key={`${tab_date_label}_tab_header`}
              sx={{ fontSize: "medium" }}
            />
          ) : (
            <Tab
              label={tab_date_label}
              {...a11yProps(index)}
              disabled
              key={`${tab_date_label}_tab_header`}
              sx={{ fontSize: "medium" }}
            />
          )
        )}
      </Tabs>

      <>
        {filmsByDateRange.map(({ film_data, tab_date_label }, index) =>
          value === index ? (
            <CustomTabPanel
              value={value}
              index={index}
              key={`${tab_date_label}_tab_panel`}
            >
              <FilmTab
                filmData={film_data}
                tabDateLabel={tab_date_label}
                //key={`${tab_date_label}_film_tab`}
              />
            </CustomTabPanel>
          ) : null
        )}
      </>
    </Box>
  );
};
