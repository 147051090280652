import React, { useState } from "react";
import { TextField, Autocomplete, ListItem, ListItemText } from "@mui/material";

interface FilmSearchBarProps {
  filmTitles: string[];
  onSelectFilm: (selection: string | null) => void;
}

export const FilmSearchBar: React.FC<FilmSearchBarProps> = ({
  filmTitles,
  onSelectFilm,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  const handleSelect = (event: React.SyntheticEvent, value: string | null) => {
    onSelectFilm(value); // Notify the parent of the selected film or null if cleared
  };

  return (
    <Autocomplete
      freeSolo
      blurOnSelect
      options={inputValue.length >= 1 ? filmTitles : []}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleSelect}
      filterOptions={(options, state) =>
        options.filter((title) =>
          title.toLowerCase().includes(state.inputValue.toLowerCase())
        )
      }
      sx={{
        marginTop: { xs: 0.5, md: 1 },
        marginBottom: { xs: 2, md: 3 },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search by Title"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            type: "text",
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                //borderColor: theme.palette.primary.main, // Optional: If you want the border to be green as well
              },
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText primary={option} />
        </ListItem>
      )}
    />
  );
};
