export const trimDescription = (
  description: string,
  isMobile: boolean
): string => {
  const sentences = description.match(/[^.!?]*[.!?]/g);
  const descriptionCharLimit = isMobile ? 150 : 400;

  if (!sentences || sentences.length === 0) {
    return description;
  }

  let displayDescription = sentences[0];

  for (let i = 1; i < sentences.length; i++) {
    if ((displayDescription + sentences[i]).length > descriptionCharLimit) {
      break;
    }

    displayDescription += "";
    displayDescription += sentences[i];
  }

  return displayDescription.trim();
};

export const convertMinutesToHoursAndMinutesString = (
  totalMinutes: number
): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let result = "";
  if (hours > 0) {
    result += `${hours}hr${hours > 1 ? "s" : ""}`;
  }
  if (minutes > 0) {
    result += `${hours > 0 ? " " : ""}${minutes}min${minutes > 1 ? "s" : ""}`;
  }

  return result || "0min";
};
