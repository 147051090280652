import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FilmTile } from "../FilmTile/FilmTile.tsx";
import { CombinedScreeningsObject } from "../../types/types.ts";
import { FilmSearchBar } from "../SearchBars/FilmSearchBar.tsx";

const initialVisibleCount = 5;

export const FilmTab: React.FC<{
  filmData: CombinedScreeningsObject[];
  tabDateLabel: string;
}> = ({ filmData, tabDateLabel }) => {
  const [selectedFilms, setSelectedFilms] =
    React.useState<CombinedScreeningsObject[]>(filmData);

  const handleFilmSelect = (filmTitle: string | null) => {
    if (filmTitle) {
      const filteredFilms = filmData.filter((film) => film.title === filmTitle);
      setSelectedFilms(filteredFilms);
    } else {
      setSelectedFilms(filmData);
    }
  };

  return (
    <>
      <FilmSearchBar
        filmTitles={filmData.map((film) => film.title)}
        onSelectFilm={handleFilmSelect}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 0,
          margin: 0,
          marginTop: { xs: 1, md: 2 },
          width: "100%",
        }}
      >
        <Stack
          spacing={{ xs: 2, md: 5 }}
          sx={{ width: "100%", margin: "0px", padding: "0px" }}
        >
          {selectedFilms.map((film, index) =>
            index < initialVisibleCount ? (
              <FilmTile
                key={`${film.title}_${tabDateLabel}_${index}`}
                title={film.title}
                description={film.description ?? ""}
                image_link={film.image_link}
                screenings={film.screenings_data}
                rating={film.rating}
                lengthInMinutes={film.length_in_minutes}
                tabDateLabel={tabDateLabel}
                lazy={false}
              />
            ) : (
              <FilmTile
                key={`${film.title}_${tabDateLabel}_${index}`}
                title={film.title}
                description={film.description ?? ""}
                image_link={film.image_link}
                screenings={film.screenings_data}
                rating={film.rating}
                lengthInMinutes={film.length_in_minutes}
                tabDateLabel={tabDateLabel}
                lazy={true}
              />
            )
          )}
        </Stack>
      </Box>
    </>
  );
};
